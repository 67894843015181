import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p>{`動画の中で「音の違い」が聞き取れない場合は、イヤホンかヘッドホンで聞いて下さい。`}<br parentName="p"></br>{`
`}{`スマホやタブレットのスピーカーではわかりにくいです。`}</p>
      <p><strong parentName="p">{`Q：`}</strong><br parentName="p"></br>{`
`}{`ボーカルなどの音が浮いて聞こえるのが、単に「音量の差」の問題なのだとしたら、大きい音のフェーダーを下げれば解決するのではないですか？あえてマスターコンプで差を埋める理由はなんですか？`}</p>
      <p><strong parentName="p">{`A：`}</strong><br parentName="p"></br>{`
`}{`やってみればわかりますが、「ボーカルが浮くから」とボーカルの音量を下げると、`}<strong parentName="p">{`単純に聞こえにくくなります`}</strong>{`。`}<br parentName="p"></br>{`
`}{`他の楽器よりも、小さくなるからです。`}</p>
      <p>{`しかし、マスターコンプを使えば、`}<strong parentName="p">{`小さくすることなく、馴染ませる`}</strong>{`ことができます。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      